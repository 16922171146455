class ZIDXAccountAdminSettings implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountAdminSettingsContainer";
    }
    getMatchURL(){
        return "/account/admin-settings";
    }
    render(){
    }
}